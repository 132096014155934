import React, { useState, useEffect, useRef } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  useMutation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Affix, Layout, Spin } from "antd";
import {
  AppHeader,
  Home,
  Host,
  Events,
  Login,
  NotFound,
  User,
} from "./sections";
import { AppHeaderSkeleton, ErrorBanner } from "./lib/components";
import { LOG_IN_AWS } from "./lib/graphql/mutations";
import {
  LogIn_AWS as LogInData,
  LogIn_AWSVariables as LogInVariables,
} from "./lib/graphql/mutations/LogIn_AWS/__generated__/LogIn_AWS";

import { Viewer } from "./lib/types";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.css";

import { createUploadLink } from "apollo-upload-client";

const apiUri = 'https://api.stmary.ziloheart.ai' || "";
const httpLink = createUploadLink({
  uri: apiUri,
});

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem("token");
  return {
    headers: {
      ...headers,
      "X-CSRF-TOKEN": token || "",
    },
  };
});

const client = new ApolloClient({
  credentials: "include",
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const initialViewer: Viewer = {
  id: null,
  token: null,
  avatar: null,
  didRequest: false,
};

const App = () => {
  const [viewer, setViewer] = useState<Viewer>(initialViewer);
  const [logIn_aws, { error }] = useMutation<LogInData, LogInVariables>(
    LOG_IN_AWS,
    {
      onCompleted: (data) => {
        console.log("useMutation: logIn_aws", data);
        if (data && data.logIn_aws) {
          setViewer(data.logIn_aws);
        }

        if (data.logIn_aws.token) {
          sessionStorage.setItem("token", data.logIn_aws.token);
        } else {
          sessionStorage.removeItem("token");
        }
      },
    }
  );

  const logInRef = useRef(logIn_aws);

  useEffect(() => {
    console.log("useeffect", logInRef);
    logInRef.current();
  }, []);

  if (!viewer.didRequest && !error) {
    return (
      <Layout className="app-skeleton">
        <AppHeaderSkeleton />
        <div className="app-skeleton__spin-section">
          <Spin size="large" tip="Launching Zilius" />
        </div>
      </Layout>
    );
  }
  const logInErrorBannerElement = error ? (
    <ErrorBanner description=" We weren't able to verify if you were logged in. Please try again later!" />
  ) : null;
  return (
    <Router>
      <Layout id="app">
        {logInErrorBannerElement}
        <Affix offsetTop={0} className="app__affix-header">
          <AppHeader viewer={viewer} setViewer={setViewer} />
        </Affix>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/host" component={Host} />
          <Route
            exact
            path="/login"
            render={(props) => (
              <Login {...props} viewer={viewer} setViewer={setViewer} />
            )}
          />
          <Route
            exact
            path="/user/:id"
            render={(props) => <User {...props} viewer={viewer} />}
          />
          <Route
            exact
            path="/events"
            render={(props) => <Events {...props} viewer={viewer} />}
            // render={(props) => <Events {...props} viewer={viewer} />}
          />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  );
};

render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// v=f{R9i8GP07
