import React, { useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import { Button, Card, Layout, Typography, Spin } from "antd";
import { ErrorBanner } from "../../lib/components";
import { LockFilled } from "@ant-design/icons";

import { Viewer } from "../../lib/types";
import {
  displaySuccessNotification,
  displayErrorMessage,
} from "../../lib/utils";

import { LOG_IN_AWS } from "../../lib/graphql/mutations";
import {
  LogIn_AWS as LogInData,
  LogIn_AWSVariables as LogInVariables,
} from "../../lib/graphql/mutations/LogIn_AWS/__generated__/LogIn_AWS";

import { AUTH_URL_AWS } from "../../lib/graphql/queries";
import { AuthUrl_AWS as AuthUrlData } from "../../lib/graphql/queries/AuthUrl_AWS/__generated__/AuthUrl_AWS";

interface Props {
  viewer: Viewer;
  setViewer: (viewer: Viewer) => void;
}
const { Content } = Layout;
const { Text, Title } = Typography;

export const Login = ({ viewer, setViewer }: Props) => {
  const viewerNotExists = viewer.id === null;

  const client = useApolloClient();
  const [
    logIn_aws,
    { data: logInData, loading: logInLoading, error: logInError },
  ] = useMutation<LogInData, LogInVariables>(LOG_IN_AWS, {
    onCompleted: (data) => {
      console.log("useMutation: login", data);
      if (data && data.logIn_aws && data.logIn_aws.token) {
        setViewer(data.logIn_aws);
        sessionStorage.setItem("token", data.logIn_aws.token);
        displaySuccessNotification("You've successfully logged in!");
      }
    },
  });
  const logInRef = useRef(logIn_aws);

  useEffect(() => {
    const code = new URL(window.location.href).searchParams.get("code");
    if (code) {
      logInRef.current({
        variables: {
          input: { code },
        },
      });
    }
  }, []);

  const handleAuthorize = async () => {
    try {
      const { data } = await client.query<AuthUrlData>({
        query: AUTH_URL_AWS,
      });

      window.location.href = data.authUrl_aws;
    } catch {
      displayErrorMessage(
        "Sorry! We weren't able to log you in. Please try again later!"
      );
    }
  };

  if (logInLoading) {
    return (
      <Content className="log-in">
        <Spin size="large" tip="Loggin you in..." />
      </Content>
    );
  }

  if (logInData && logInData.logIn_aws) {
    const { id: viewerId } = logInData.logIn_aws;
    return <Redirect to={`/events`}></Redirect>;
    // return <Redirect to={`/user/${viewerId}`} />;
  }

  const logInErrorBannerElement = logInError ? (
    <ErrorBanner description="Sorry! We weren't able to log you in.Please try again later!" />
  ) : null;

  const logInElement = viewerNotExists ? (
    <Content className="log-in">
      {logInErrorBannerElement}
      <Card className="log-in-card">
        <div className="log-in-card__intro">
          <Title level={3} className="log-in-card__intro-title">
            <span role="img" aria-label="wave"></span>
          </Title>
          <Title level={3} className="log-in-card__intro-title">
            Log in to Zilius!
          </Title>
          <Text> Sign in via secure sign in below!</Text>
        </div>
        <Button
          className="log-in-card__google-button"
          type="primary"
          onClick={handleAuthorize}
        >
          <LockFilled /> Sign in
        </Button>

        <Text type="secondary">
          Note: The link above will redirect you to secure login form to keep
          your credentials safe!
        </Text>
      </Card>
    </Content>
  ) : (
    <Redirect to={`/events`}></Redirect>
  );

  return logInElement;
};
