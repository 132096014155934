import { message, notification } from 'antd';

export const iconColor = "#3d9c7d";

export const displaySuccessNotification = (message: string, description?: string) => {
    return notification["success"]({
        message,
        description,
        placement: 'topLeft',
        style: {
            marginTop: 50
        }
    })
}

export const displayErrorMessage = (error: string) => {
    return message.error(error)
}